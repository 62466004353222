<template>
  <b-modal
    :visible="showModal"
    title="Novo pedido de resgate de saldo"
    :hide-footer="true"
    @hide="hideModal"
  >
    <div v-if="modoResgate == config.RESGATE_TIPO_DINHEIRO">
      <div v-if="saldo < config.RESGATE_VALOR_MINIMO_DINHEIRO">
        <div class="row">
          <div class="col-sm-auto">
            <h1 class="text-center text-info">
              <i class="fas fa-info-circle"></i>
            </h1>
          </div>
          <div class="col-sm-10">
            <p>
              Você ainda não tem saldo suficiente para o resgate em dinheiro. O
              <b
                >valor mínimo é de
                {{ formatacaoMoeda(config.RESGATE_VALOR_MINIMO_DINHEIRO) }}.</b
              >
            </p>
            <p>
              Continue compartilhando seu link para acumular mais
              <b>dinheiro!</b>
            </p>
          </div>
        </div>
      </div>
      <div v-else>
        <b>Resgate em dinheiro</b>
        <p>Prencha os dados do <b>PIX</b> onde depositaremos o saldo.</p>

        <ValidationObserver v-slot="{ handleSubmit, invalid }">
          <form @submit.prevent="handleSubmit(realizarResgate)">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Tipo da chave PIX</label>
                  <ValidationProvider
                    name="tipo da chave pix"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <select
                      class="form-control"
                      v-model="dados.tipoChavePix"
                      :class="classes"
                      @change="limpar"
                    >
                      <option :value="undefined" selected>Selecione...</option>
                      <option value="CPF">CPF</option>
                      <option value="EMAIL">Email</option>
                      <option value="TELEFONE">Telefone</option>
                      <option value="CHAVE-ALEATORIA">Chave Aleatória</option>
                    </select>
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-6">
                <div v-show="dados.tipoChavePix" class="form-group">
                  <label>Chave Pix</label>
                  <ValidationProvider
                    v-if="dados.tipoChavePix == 'CPF'"
                    name="chave pix CPF"
                    rules="required|cpf"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      type="text"
                      class="form-control"
                      v-model="dados.cpfConta"
                      :class="classes"
                      placeholder="Chave pix CPF"
                      v-mask="'###.###.###-##'"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>

                  <ValidationProvider
                    v-if="dados.tipoChavePix == 'EMAIL'"
                    name="email"
                    rules="required|email"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      type="text"
                      class="form-control"
                      v-model="dados.emailPix"
                      placeholder="Chave pix email"
                      :class="classes"
                      v-mask="dados.emailPix"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <ValidationProvider
                    v-if="dados.tipoChavePix == 'TELEFONE'"
                    name="telefone"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      type="tel"
                      class="form-control"
                      v-model="dados.telefonePix"
                      :class="classes"
                      placeholder="Chave pix Telefone"
                      v-mask="'(##) #####-####'"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <ValidationProvider
                    v-if="dados.tipoChavePix == 'CHAVE-ALEATORIA'"
                    name="chave pix aleatória"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      type="text"
                      class="form-control"
                      v-model="dados.chaveAleatoriaPix"
                      :class="classes"
                      placeholder="Chave pix aleatória"
                      v-mask="dados.cpfConta"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Agência</label>
                  <ValidationProvider
                    name="agência"
                    rules="required|min:3"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      type="text"
                      class="form-control"
                      v-model="dados.agencia"
                      :class="classes"
                      placeholder="Agência"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Conta</label>
                  <ValidationProvider
                    name="conta"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      type="text"
                      class="form-control"
                      v-model="dados.conta"
                      :class="classes"
                      placeholder="Conta"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Nome do titular</label>
                  <ValidationProvider
                    name="nome do titular"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      type="text"
                      class="form-control"
                      v-model="dados.nomeTitular"
                      :class="classes"
                      placeholder="Nome titular"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <hr class="mt-3 mb-3" />
            <button
              class="btn btn-success btn-block"
              type="submit"
              :disabled="loading || invalid"
            >
              <span v-if="loading">Enviando...</span>
              <span v-else>Enviar pedido de resgate</span>
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <div v-else>
      <b>Resgate em cupom desconto</b>
      <p>
       O desconto de <b v-if="porcentagemCupom == 2">5%</b>
             <b v-if="porcentagemCupom == 4">10%</b>
             <b v-if="porcentagemCupom == 6">15%</b> será aplicado no seu próprio seguro de bicicleta nos próximos 12 meses.
      </p>
      <b-button
        class="btn-block  btn-lg w-100"
        variant="success"
        @click.prevent="pedidoDesconto()"
        :disabled="loading"
      >
        <span v-if="!loading">Enviar pedido de resgate</span>
        <span v-if="loading">
          <small class="text-white">Enviando...</small>
        </span>
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";

import config from "../../config";
import captureError from "../../helpers/captureError";
import formatacaoMoeda from "../../helpers/formatacaoMoeda";

export default {
  props: ["showModal", "modoResgate", "saldo", "idCliente","porcentagemCupom"],
  data() {
    return {
      // config,
      formatacaoMoeda,
      config,
      dados: {
        cpfConta: undefined,
        emailPix: undefined,
        telefonePix: undefined,
        agencia: undefined,
        conta: undefined,
        nomeTitular: undefined,
        tipoChavePix: undefined,
        chaveAleatoriaPix: undefined,
        idCliente: undefined,
      },
      loading: false,
    };
  },
  watch: {
    showModal: function(newVal, oldVal) {
      if (newVal != oldVal) {
        this.buscarDadosBancarios();
      }
    },
  },
  methods: {
    hideModal() {
      this.$emit("hide");
    },
    async buscarDadosBancarios() {
      if (!this.idCliente) {
        return;
      }
      this.loading = true;
      try {
        var response = await axios.get(
          `${config.API_URLV2}/mgmTransacaoIndicacao/dadosBancarios?idCliente=${this.idCliente}`,
          {}
        );
        var dados = response.data;

        this.dados.cpfConta = dados.cpfConta;
        this.dados.emailPix = dados.emailPix;
        this.dados.telefonePix = dados.telefonePix;
        this.dados.agencia = dados.agencia;
        this.dados.conta = dados.conta;
        this.dados.nomeTitular = dados.nomeTitular;
        this.dados.tipoChavePix = dados.tipoChavePix;
        this.dados.chaveAleatoriaPix = dados.chaveAleatoriaPix;
        this.dados.idCliente = this.idCliente;
        this.loading = response.data.false;
      } catch (error) {
        captureError(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível consultar seus dados do pix. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
    limpar() {
      this.dados.cpfConta = undefined;
      this.dados.emailPix = undefined;
      this.dados.telefonePix = undefined;
      this.dados.chaveAleatoriaPix = undefined;
    },
    async pedidoDesconto(){
 try {
        this.loadingSalvar = true;
        await axios.post(`${config.API_URLV2}/pedidoresgate/desconto/${this.idCliente}`);

        Swal.fire("Pedido de resgate efetuado com sucesso!", "", "success");

        this.loadingSalvar = false;
        this.hideModal();
      } catch (e) {
        captureError(e);
        this.loadingSalvar = false;

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível solicitar o resgate. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
    async realizarResgate() {
      try {
        this.loadingSalvar = true;

        var data = JSON.parse(JSON.stringify(this.dados));
        await axios.post(`${config.API_URLV2}/pedidoresgate`, data);

        Swal.fire("Pedido de resgate efetuado com sucesso!", "", "success");

        this.loadingSalvar = false;
        this.hideModal();
      } catch (e) {
        captureError(e);
        this.loadingSalvar = false;

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível solicitar o resgate. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
  },
};
</script>
