<template>
  <div>
    <div>
      <div class=" text-center pt-5">
        <i class="fas fa-wallet  icone-principal"></i>
        <h3>Saldo indicação</h3>
        <div class="h-line" style="width: 142px"></div>
        <p class="mt-3 ">
          Aqui você pode ver todas as suas indicações.
        </p>
      </div>
    </div>
    <hr class="mt-1" />
    <div class="saldo-indicacao">
      <div class="row card-body">
        <div class="col-md-12 mb-3 " v-if="loading == false && !pedidoAtual">
          <h5 class="ml-1  "  v-if="podeResgatarSaldo">
            Escolha o tipo de resgate
          </h5>
          <div>
          <button
            class="btn btn-button btn-success mr-3"
            text="Resgatar saldo"
            variant="warning"
            right
            v-if="
              podeResgatarSaldo &&
                (porcentagemCupom == 2 ||
                  porcentagemCupom == 4 ||
                  porcentagemCupom == 6)
            "
            @click="exibirModalIndicacaoResgate(config.RESGATE_TIPO_CUPOM)"
          >
            <!-- <i class="far fa-currency"></i> -->
           Clique aqui para ganhar :
            <b v-if="porcentagemCupom == 2">5%</b>
            <b v-if="porcentagemCupom == 4">10%</b>
            <b v-if="porcentagemCupom == 6">15%</b>
          </button>
          
          <button
            class="btn btn-button btn-success"
            style="cursor:pointer"
            text="Resgatar saldo"
            variant="warning"
            right
            v-if="podeResgatarSaldo"
            @click="exibirModalIndicacaoResgate(config.RESGATE_TIPO_DINHEIRO)"
          >
            <!-- <i class="far fa-currency"></i> -->
           Clique aqui para resgatar em dinheiro: <b>{{ formatacaoMoeda3(saldo) }}</b>
          </button></div>
        </div>
      </div>
      <div class="alert alert-warning mt-3" v-if="!loading && pedidoAtual">
        <p>
          Seu pedido de
          <b>{{
            pedidoAtual.tipo == config.RESGATE_TIPO_CUPOM
              ? "resgate em cupom"
              : "resgate em dinheiro"
          }}</b>
          de 
          <b v-if="pedidoAtual.tipo == config.RESGATE_TIPO_CUPOM">{{ pedidoAtual.valorResgate}}%</b> 
          <b v-else>{{ formatacaoMoeda3(pedidoAtual.valorResgate) }}</b>
          feito no dia
          <b>{{ formatacaoData.formatarDataHoraLocal(pedidoAtual.criado) }}</b>
          está sendo analisado por nossa equipe. Quando o processo for concluído
          entraremos em contato.<br />
          Obs: Aguarde a análise para fazer outro pedido.
        </p>
        <!-- <button
        class="btn btn-sm btn-link text-danger px-0"
        @click="cancelarPedidoAtual()"
        :disabled="loadingCancelarPedido"
      >
        <span v-if="!loadingCancelarPedido"
          >Cancelar este pedido de resgate.</span
        >
        <span v-if="loadingCancelarPedido">
          <small class="text-purple">Cancelando...</small>
        </span>
      </button> -->
      </div>

      <div v-if="!loading && transacoes.length > 0">
        <div class="only-desktop card card-body">
          <table class="table ">
            <thead>
              <tr>
                <th>Data</th>
                <th>Nome do indicado</th>
                <th>Saldo</th>
                <th>Situação</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in transacoes"
                :key="'transacao' + index"
              >
                <td>
                  {{ formatacaoData.formatarDataHoraLocal(item.criado) }}
                </td>
                <td>
                  <span>{{ item.nomeClienteIndicado }}</span>
                </td>
                <td v-if="
                      pedidoAtual &&
                        item.dataResgateConcluido == null &&
                        item.idPedidoResgate == pedidoAtual.id && pedidoAtual.tipo == config.RESGATE_TIPO_CUPOM
                    ">
                  <span>5%</span>
                </td>
                <td v-else>
                  <span v-if="item.tipo == config.RESGATE_TIPO_CUPOM">Cupom</span>
                  <span v-else>{{ formatacaoMoeda3(item.saldo) }}</span>
                </td>
                <td>
                  <div v-if="item.dataResgateConcluido">
                    <b-badge variant="success" pill>Resgate concluído</b-badge>
                    <br /><small
                      >Em:
                      {{
                        formatacaoData.formatarDataHoraLocal(
                          item.dataResgateConcluido
                        )
                      }}</small
                    >
                  </div>
                  <div
                    v-else-if="
                      pedidoAtual &&
                        item.dataResgateConcluido == null &&
                        item.idPedidoResgate == pedidoAtual.id
                    "
                  >
                    <b-badge variant="warning" pill>Resgate solicitado</b-badge>
                  </div>
                  <div v-else>
                    <b-badge variant="primary" pill>Não resgatado</b-badge>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="only-mobile">
          <div
            class="card card-body mb-3"
            v-for="(item, index) in transacoes"
            :key="'transacaoMobile' + index"
          >
            <div>
              Data: {{ formatacaoData.formatarDataHoraLocal(item.criado) }}
            </div>
            <div>
              <span>Nome do indicado: {{ item.nomeClienteIndicado }}</span>
            </div>
           <div v-if="item.tipo == config.RESGATE_TIPO_CUPOM && item.dataResgateConcluido">
              <span>Cupom</span>
            </div>
            <div v-else>
              <span>Saldo: {{ formatacaoMoeda3(saldo) }}</span>
            </div>
            <div>
              <div v-if="item.dataResgateConcluido">
                <b-badge variant="success" pill>Resgate concluído</b-badge>
                <br /><small
                  >Em:
                  {{
                    formatacaoData.formatarDataHoraLocal(
                      item.dataResgateConcluido
                    )
                  }}</small
                >
              </div>
              <div
                v-else-if="
                  pedidoAtual &&
                    item.dataResgateConcluido == null &&
                    item.idPedidoResgate == pedidoAtual.id
                "
              >
                <b-badge variant="warning" pill>Resgate solicitado</b-badge>
              </div>
              <div v-else>
                <b-badge variant="primary" pill>Não resgatado</b-badge>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!loading && linkIndicacao.length > 0" class="mt-2 ">
        Olá
        <span style="text-transform: capitalize;">{{
          auth.getUserInfo().nome
        }}</span
        >, <br />
        <p>
          Obrigado por confiar no Seguro Bike Registrada.
        </p>

        <strong>
          Agora você faz parte do Pedala Junto com a Bike Registrada  </strong
        ><br />
        <p>Este é seu link de indicação:</p>
        <div class="row mb-4">
          <div class="col-sm-12 col-md-10">
            <div class="input-group mb-3">
              <b-form-input
                :value="linkIndicacao"
                id="myInput"
                type="text"
                readonly
              ></b-form-input>
              <div class="input-group-append">
                <b-button
                  class="btn-success"
                  @click="copiarLink()"
                  variant="primary"
                  >Copiar link</b-button
                >
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-2">
            <a
              class="mb-3"
              :href="
                `https://api.whatsapp.com/send?text=Olá, fiz o seguro da minha bike e agora pedalo protegido(a). Recomendo você fazer o seu também.                                                                                                  
                                                        
                                                        Clique no link abaixo para proteger sua bike agora mesmo 

                                                        ${linkIndicacao} 
                                                         
                                                        Abraços, ${nomeCompleto}`
              "
              target="_blank"
              variant="success"
              ><b-img src="/img/whatsapp.svg" height="42px"></b-img>
            </a>
          </div>
        </div>

        Indique seu link para maior número de pessoas que puder, assim você
        acumula mais dinheiro!
        <p>
          Regras: Para cada pessoa indicada você pode ganhar descontos no seu Seguro Bike (de acordo com o número de indicações) ou 
          <strong>
            {{
              formatacaoMoeda3(config.SALDO_RESGATE_MEMBER_GET_MEMBER)
            }}</strong
          >
          em cashback!.
          <br />
          Utilize seu link em todas as recomendações. Ele é a sua garantia!
        </p>
      </div>

      <div v-if="loading" class="text-center">
        <lottie-player
          src="/anim/mountain_bike.json"
          background="transparent"
          speed="1.7"
          style="width: 72px; height: 72px; margin:0 auto;"
          autoplay
          loop
          class="mt-3"
        ></lottie-player>
        <div>Carregando, por favor aguarde...</div>
      </div>
    </div>
    <ModalResgateIndicacao
      :showModal="showModalResgateIndicacao"
      :modoResgate="modoResgate"
      :porcentagemCupom="porcentagemCupom"
      :idCliente="idCliente"
      :saldo="saldo"
      @hide="hideModalResgateIndicacao"
    />
  </div>
</template>

<script>
import ModalResgateIndicacao from "../../components/MemberGetMember/ModalResgateIndicacao.vue";

import config from "../../config";
import auth from "../../auth";
import Swal from "sweetalert2";
import axios from "axios";
import captureError from "../../helpers/captureError";
import formatacaoMoeda3 from "../../helpers/formatacaoMoeda3";
import formatacaoData from "../../helpers/formatacaoData";

export default {
  components: {
    ModalResgateIndicacao,
  },
  data() {
    return {
      axios,
      auth,
      formatacaoMoeda3,
      formatacaoData,
      config,
      idCliente: undefined,
      pedidoAtual: undefined,
      loadingCancelarPedido: false,
      loading: false,
      saldo: 0,
      porcentagemCupom: 0,
      transacoes: [],
      showModalResgateIndicacao: false,
      modoResgate: undefined,
      linkIndicacao: "",
      nomeCompleto: "",
    };
  },
  created() {
    var userInfo = auth.getUserInfo();
    if (userInfo && userInfo.id) {
      this.idCliente = userInfo.id;
      this.nomeCompleto = userInfo.nome;
      this.consultarSaldo();
    } else {
      this.$router.replace("/login");
    }
  },
  beforeRouteEnter(to, from, next) {
    document.body.classList.add("hide-external-buttons");
    next();
  },
  beforeRouteLeave(to, from, next) {
    document.body.classList.remove("hide-external-buttons");
    next();
  },
  computed: {
    podeResgatarSaldo() {
      return (
        !this.loading &&
        this.transacoes.length > 0 &&
        this.saldo > 0 &&
        !this.pedidoAtual
      );
    },
  },
  methods: {
    async gerarLinkClienteLogado() {
      if (!this.idCliente) {
        return;
      }
      this.loading = true;

      try {
        var response = await axios.post(
          `${config.API_URLV2}/mgmTransacaoIndicacao/usuarioLogado/${this.idCliente}`,
          {}
        );
        this.linkIndicacao = response.data;
        this.loading = false;
        return;
      } catch (error) {
        captureError(error);
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível buscar seu link. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
    copiarLink() {
      var copyText = document.getElementById("myInput");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");

      Swal.fire({
        position: "center",
        icon: "success",
        title: "O link de indicação foi copiado!",
        text: `${copyText.value}`,
        showConfirmButton: true,
        timer: 20000,
      });
    },
    calcularSaldo(transacoes) {
      var saldo = 0;
      this.porcentagemCupom = 0;
      for (let index = 0; index < transacoes.length; index++) {
        var transacao = transacoes[index];
        if (!transacao.dataResgateConcluido) {
          saldo += transacao.saldo;
          this.porcentagemCupom = this.porcentagemCupom + 1;
        }
      }
      return saldo;
    },
    async consultarSaldo() {
      this.loading = true;
      try {
        if (!this.idCliente) {
          return;
        }
        var response = await axios.get(
          `${config.API_URLV2}/mgmTransacaoIndicacao/detalhes?idCliente=${this.idCliente}`,
          {}
        );

        var transacoes = response.data.transacoes;
        if (response?.data?.mgmLinkIndicacao) {
          this.saldo = this.calcularSaldo(transacoes);
          this.transacoes = transacoes;
          this.pedidoAtual = response.data.pedidoResgateAtual;
          this.linkIndicacao = response.data.mgmLinkIndicacao;
        } else {
          await this.gerarLinkClienteLogado();
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        captureError(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível consultar seu saldo. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
    exibirModalIndicacaoResgate(modoResgate) {
      this.modoResgate = modoResgate;
      this.showModalResgateIndicacao = true;
    },
    hideModalResgateIndicacao() {
      this.showModalResgateIndicacao = false;
      this.consultarSaldo();
    },
    async cancelarPedidoAtual() {
      var result = await Swal.fire({
        title: "Tem certeja que deseja cancelar este pedido?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });

      if (result?.isConfirmed == false || result?.dismiss == "cancel") {
        return;
      }

      this.loadingCancelarPedido = true;
      try {
        await axios.delete(
          `${config.API_URLV2}/pedidoresgate/${this.pedidoAtual.id}`
        );

        this.loadingCancelarPedido = false;

        Swal.fire("Pedido de resgate cancelado com sucesso!", "", "success");

        this.consultarSaldo();
      } catch (error) {
        this.loadingCancelarPedido = false;
        captureError(error);
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível cancelar seu pedido de resgate. Tente novamente mais tarde ou contate o suporte.",
        });
        location.reload();
      }
    },
  },
};
</script>

<style scoped>
.form-control {
  height: calc(1.5em + 1rem + 5px);
  font-size: 1.1rem;
}
.minha-conta {
  height: 100%;
  /* background-color: #f7f7f7; */
}

.icone-principal {
  font-size: 43px;
  color: #28a745;
  background: -webkit-linear-gradient(#76b566, #28a745);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.only-mobile {
  display: none;
}
.only-desktop {
  display: block;
}
.saldo-indicacao {
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
}
@media screen and (max-width: 768px) {
  .only-mobile {
    display: block;
  }
  .only-desktop {
    display: none;
  }
}
</style>
